export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    const api = $fetch.create({
        baseURL: config.public.apiBaseUrl as string,
        onRequest({ options }) {
            const token = useAuthStore().getToken.value;
            if (token) {
                const headers = options.headers
                if (Array.isArray(headers)) {
                    headers.push(['Authorization', `Bearer ${token}`])
                } else if (headers instanceof Headers) {
                    headers.set('Authorization', `Bearer ${token}`)
                }
            }
        },
        async onResponseError({ response }) {
            if (response.status === 401) {
                await nuxtApp.runWithContext(() => navigateTo('/'))
            }
        }
    })

    // Expose to useNuxtApp().$api
    return {
        provide: {
            api
        }
    }
})