<template>
    <img
        v-if="imageUrl"
        :src="imageUrl"
        :alt="user?.userName ?? 'image not found'"
        :class="props.class"
        @error="handleImageError"
    />
    <div  v-else-if="error" :class="['bg-slate-200', props.class]"> </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useAuthStore } from '~/stores/auth';

const props = defineProps<{ class: string }>();
const error = ref<string>();
const imageUrl = ref<string>();
const user = reactive(useAuthStore().getUser);

const getImageUrl = async () => {
    if(useAuthStore().getUser.value){
        const {error: apiError, data, execute} = useAPIClientSide<MediaSource>('accounts/profile-picture');
        await execute();
        if (apiError.value) {
            error.value = apiError?.value ?? 'API error with no message to show.';
            return '';
        }
        let blobUrl = '';
        if (data.value != null) blobUrl = URL.createObjectURL(data.value);
        return blobUrl;
    };
    return '';
};

watchEffect( async () => {
    if (user) {
        imageUrl.value = await getImageUrl();
    }
});

const handleImageError = () => { 
    error.value = 'Html image tag error';
};
</script> 