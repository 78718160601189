<template>
    <header class="text-fLightText py-4 px-6 flex flex-cols items-center">
        <div class="flex flex-grow items-center space-x-4 justify-start">
            <a href="/" @click.prevent="navigateToHome">
                <AppLogo class="h-8 my-2 w-auto text-fLightText" />
            </a>
            <span class="text-lg italic">find easy and satisfying treats</span>
        </div>
        <!-- Horizontal navi -->
        <div class="justify-end hidden md:flex items-center absolute right-0">
            <nav>
                <ul class="flex space-x-8 mr-8">
                    <li v-for="link in filteredLinks" :key="link.to"
                        class="relative"
                        @mouseenter="toggleDropdown(link.label)"
                        @mouseleave="closeDropdown">
                        <NuxtLink :to="link.to" class="font-semibold text-xl mt-2 hover:underline flex items-center">
                            {{ link.label }}
                            <UIcon v-if="link.children && link.children.length > 0" class="ml-1 w-5 h-5" name="i-heroicons-chevron-down"/>
                        </NuxtLink>

                        <ul v-if="link.children && link.children.length > 0 && isDropdownOpen(link.label)"
                            class="absolute left-0 mt-1 bg-gray-100 text-gray-800 shadow-lg rounded-md py-2 w-44 transition-opacity duration-200 z-10"
                            @mouseenter="keepDropdownOpen"
                            @mouseleave="closeDropdown">
                            <li v-for="sublink in link.children" :key="sublink.to" class="px-4 py-2 hover:bg-gray-200 rounded-md">
                                <NuxtLink :to="sublink.to">{{ sublink.label }}</NuxtLink>
                            </li>
                        </ul>
                    </li>
                    <li v-if="user" class="relative" @mouseenter="toggleDropdown('profile')" @mouseleave="closeDropdown">
                        <NuxtLink to="/profile" class="flex items-center">
                            <ProfileImage class="size-12 object-cover rounded-full cursor-pointer" />
                        </NuxtLink>
                        <ul v-if="isDropdownOpen('profile')"
                            class="absolute right-0 top-full mt-1 bg-gray-100 text-gray-800 shadow-lg rounded-md py-1 w-40 z-10"
                            @mouseenter="keepDropdownOpen"
                            @mouseleave="closeDropdown">
                            <li class="px-4 py-2 hover:bg-gray-200 rounded-md">
                                <NuxtLink to="/profile">Profiil</NuxtLink>
                            </li>
                            <li class="px-4 py-2 hover:bg-gray-200 rounded-md">
                                <button @click="logout" class="w-full text-red-800 text-left">Logi välja</button>
                            </li>
                        </ul>
                    </li>
                    <li v-else>
                        <TmpAuth/>
                    </li>
                </ul>
            </nav> 
        </div>
        <!-- Vertical navi (mobile friendly) -->
        <div class="md:hidden flex items-center">
            <button @click="toggleMenu" class="text-white">
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
            </button>
        </div>
        <div v-if="isMenuOpen" class="fixed inset-0 bg-fGreen-950 z-50 py-6 pr-6 pl-12">
            <div class="flex flex-grow justify-end">
                <button @click="toggleMenu" class="text-white">
                    <UIcon class="w-8 h-8" name="i-heroicons-x-mark"/>
                </button>
            </div>
            <nav>
                <ul class="flex flex-col justify-center space-y-8">
                    <li v-for="link in filteredLinks" :key="link.to"
                        class="relative"
                        @mouseenter="toggleDropdown(link.label)"
                        @mouseleave="closeDropdown">
                        <NuxtLink :to="link.to" class="font-semibold hover:underline flex items-center">
                            {{ link.label }}
                        </NuxtLink>
                        <ul v-if="link.children && link.children.length > 0" class="space-y-6 mt-4">
                            <li v-for="sublink in link.children" :key="sublink.to" class="pl-4 hover:underline">
                                <NuxtLink :to="sublink.to">{{ sublink.label }}</NuxtLink>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <NuxtLink v-if="user" to="/profile">
                            <ProfileImage class="size-12 object-cover rounded-full"/>
                        </NuxtLink>
                        <TmpAuth v-else/>
                    </li>
                </ul>
            </nav>              
        </div>

    </header>
</template>

<script setup lang="ts">
const user = reactive(useAuthStore().getUser);

const dropdownLabel = ref<string | null>(null);
let timeoutId: ReturnType<typeof setTimeout> | undefined;
const isDropdownOpen = (label: string) => dropdownLabel.value === label;

const toggleDropdown = (label: string) => {
    dropdownLabel.value = label;
};

const closeDropdown = () => {
    timeoutId = setTimeout(() => {
        dropdownLabel.value = null;
    }, 200);
};

const keepDropdownOpen = () => {
    if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
        timeoutId = undefined;
    }
};

const logout = () => {
    const { clearSession } = useAuthStore();
    clearSession();
    navigateTo('/'); 
};

const navigateToHome = () => {
    navigateTo("/");
}

// Mobile menu start
const isMenuOpen = ref(false);

const toggleMenu = () => {
    isMenuOpen.value = !isMenuOpen.value;
};
// Mobile menu end

const links = [
{ label: "Avaleht", to: "/", authRequired: false },
    { 
        label: "Retseptid", 
        to: "/recipes",
        children: [
            { label: "Lisa uus retsept", to: "/recipes/add", authRequired: true },
            { label: "Lemmikretseptid", to: "/recipes/favorite", authRequired: true },
            { label: "Minu retseptid", to: "/recipes/personal", authRequired: true }
        ],
        authRequired: false
    },
    { label: "Sahver", to: "/pantry",  authRequired: true }
];

const filteredLinks = computed(() => 
    links.map(link => {
        if (link.children) {
            return {
                ...link,
                children: link.children.filter(child => !child.authRequired || user.value)
            };
        }
        return link;
    }).filter(link => !link.authRequired || user.value)
);
</script>