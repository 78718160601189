<template>
    <div class="flex">
        <button @click="login" :disabled="isLoggedIn" 
            class="bg-white font-semibold text-xl text-fGreen-950 py-1.5 px-2 rounded-lg disabled:bg-gray-500 disabled:text-gray-800 hover:bg-light-100 active:bg-light-200">
            Logi sisse
        </button>
        <div v-if="error" class=" bg-red-500 p-1 rounded-lg">{{ error }}</div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useAuthStore } from '~/stores/auth';

const error = ref<string | null>(null);
const isLoggedIn = ref(false); 

const { getToken } = useAuthStore();

onMounted(() => {
    isLoggedIn.value = getToken.value != null;
});

const login = async () => {
    navigateTo("/login");
}

</script>