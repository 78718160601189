import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as pantrymV41CHGeTBMeta } from "/app/pages/pantry.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as editMmhFMorhOHMeta } from "/app/pages/recipes/[id]/edit.vue?macro=true";
import { default as indexfSyTVaJtRhMeta } from "/app/pages/recipes/[id]/index.vue?macro=true";
import { default as addTqHFyFE70gMeta } from "/app/pages/recipes/add.vue?macro=true";
import { default as favoriteiraAgIn8DwMeta } from "/app/pages/recipes/favorite.vue?macro=true";
import { default as indexVy2tsFzaDdMeta } from "/app/pages/recipes/index.vue?macro=true";
import { default as personalLkP1QuLQrcMeta } from "/app/pages/recipes/personal.vue?macro=true";
import { default as registermu8R7rSP5uMeta } from "/app/pages/register.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login.vue")
  },
  {
    name: "pantry",
    path: "/pantry",
    component: () => import("/app/pages/pantry.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue")
  },
  {
    name: "recipes-id-edit",
    path: "/recipes/:id()/edit",
    component: () => import("/app/pages/recipes/[id]/edit.vue")
  },
  {
    name: "recipes-id",
    path: "/recipes/:id()",
    component: () => import("/app/pages/recipes/[id]/index.vue")
  },
  {
    name: "recipes-add",
    path: "/recipes/add",
    component: () => import("/app/pages/recipes/add.vue")
  },
  {
    name: "recipes-favorite",
    path: "/recipes/favorite",
    meta: favoriteiraAgIn8DwMeta || {},
    component: () => import("/app/pages/recipes/favorite.vue")
  },
  {
    name: "recipes",
    path: "/recipes",
    component: () => import("/app/pages/recipes/index.vue")
  },
  {
    name: "recipes-personal",
    path: "/recipes/personal",
    meta: personalLkP1QuLQrcMeta || {},
    component: () => import("/app/pages/recipes/personal.vue")
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/app/pages/register.vue")
  }
]