import { defineStore } from 'pinia'
import type { UserSession } from '~/types/auth/userSession'
import type { User } from '~/types/user'

export const useAuthStore = defineStore('auth', () => {
    const user = ref<User | undefined>();
    const token = ref<string | undefined>();

    const getUser = computed(() => user);
    const getToken = computed(() => token);
    
    const setSession = async (userSession : UserSession) : Promise<boolean> => {
        if (!userSession.token || !user) return false;
        user.value = userSession.user;
        token.value = userSession.token;
        sessionStorage.setItem('auth_token', userSession.token);
        return true;
    };

    const clearSession = async () => {
        user.value = undefined;
        token.value = undefined;
        sessionStorage.removeItem('auth_token');
    };

    return {getUser, getToken, setSession, clearSession}
})