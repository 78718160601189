import revive_payload_client_oEmSvHuidE from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.22_oqeo7wnw6znx74qmoinvlowkxi/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0XwU6hK2jT from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.22_oqeo7wnw6znx74qmoinvlowkxi/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_MbqdPAopVm from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.22_oqeo7wnw6znx74qmoinvlowkxi/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_f60sFP2hag from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.22_oqeo7wnw6znx74qmoinvlowkxi/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3FOkQRtYJN from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.22_oqeo7wnw6znx74qmoinvlowkxi/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_CgmC5Mkolg from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.22_oqeo7wnw6znx74qmoinvlowkxi/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_9BdyHFJeoz from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.22_oqeo7wnw6znx74qmoinvlowkxi/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_FhhMYiS9rU from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.22.4_vue@3.5.8/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_h60184OeZW from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.6.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.22_oqeo7wnw6znx74qmoinvlowkxi/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import session_client_MtT3GIDJO5 from "/app/node_modules/.pnpm/nuxt-auth-utils@0.5.0_magicast@0.3.5_rollup@4.22.4/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import slideovers_WpJPlNVWRg from "/app/node_modules/.pnpm/@nuxt+ui@2.18.6_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_terser@5.33.0__vue@3.5.8/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_b4CTUG8sKc from "/app/node_modules/.pnpm/@nuxt+ui@2.18.6_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_terser@5.33.0__vue@3.5.8/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_Tz0ZlO0JIX from "/app/node_modules/.pnpm/@nuxt+ui@2.18.6_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_terser@5.33.0__vue@3.5.8/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_1cbaIDrZwQ from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.22.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_yHVbivbDIG from "/app/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.22.4_vite@5.4.7_@types+node@22.6.1_terser@5.33.0__vue@3.5.8/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import api_GFfDXud5Cr from "/app/plugins/api.ts";
export default [
  revive_payload_client_oEmSvHuidE,
  unhead_0XwU6hK2jT,
  router_MbqdPAopVm,
  payload_client_f60sFP2hag,
  navigation_repaint_client_3FOkQRtYJN,
  check_outdated_build_client_CgmC5Mkolg,
  chunk_reload_client_9BdyHFJeoz,
  plugin_vue3_FhhMYiS9rU,
  components_plugin_KR1HBZs4kY,
  prefetch_client_h60184OeZW,
  session_client_MtT3GIDJO5,
  slideovers_WpJPlNVWRg,
  modals_b4CTUG8sKc,
  colors_Tz0ZlO0JIX,
  plugin_client_1cbaIDrZwQ,
  plugin_yHVbivbDIG,
  api_GFfDXud5Cr
]