import { ref } from 'vue';

export function useAPIClientSide<T>(
    url: string | (() => string),
    options: RequestInit = {}
) {
    const data = ref<T | null>(null);
    const error = ref<string | null>(null);
    const loading = ref<boolean>(false);

    const execute = async () => {
        loading.value = true;
        error.value = null;

        try {
            const { $api } = useNuxtApp();
            const response = await $api(url.toString(), {
                ...options,
                method: options.method as 'get' | 'post' | 'put' | 'delete',
                headers: {
                    ...(options.headers || {}),
                },
            });
            data.value = response;
        } catch (err: any) {
            error.value = err.message || 'Could not get error message, but something went wrong';
        } finally {
            loading.value = false;
        }
    };

    return { data, error, loading, execute };
}
